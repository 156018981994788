const crypto = require('crypto');

if (!window.crypto && !global.crypto) {
	Object.defineProperty(global.self, 'crypto', {
		value: {
			getRandomValues: arr => crypto.randomBytes(arr.length),
		},
	});
}


function uuid(str) {
	if (str) return /^[\dA-F]{8}-[\dA-F]{4}-4[\dA-F]{3}-[89AB][\dA-F]{3}-[\dA-F]{12}$/.test(str);
	const crpt = window.crypto || (global && global.crypto) || window.msCrypto;
	const values = crpt.getRandomValues(new Uint8Array(16));
	const a = [];
	for (let i = 0; i < 16; i++) { const val = values[i].toString(16).toUpperCase(); a.push(val.length > 1 ? val : '0' + val); }
	const groups = [];
	groups.push(a[0] + a[1] + a[2] + a[3]);
	groups.push(a[4] + a[5]);
	groups.push((a[6] + a[7]).replace(/^[\dA-F]/, '4'));
	groups.push((a[8] + a[9]).replace(/^[\dA-F]/, (match) => ['8', '9', 'A', 'B'][Math.ceil((window.parseInt(match, 16) + 1) / 4) - 1]));
	groups.push(a[10] + a[11] + a[12] + a[13] + a[14] + a[15]);
	return groups.join('-');
}

uuid.docs = function uuid() {
	return {
		params: {
			uuidToCheck: {
				type: String,
				description: 'If one exists will return a boolean of if it is a valid uuid or not',
			},
		},
	};
};

export { uuid };
